<template>
  <div
    :id="targetField"
    uk-modal
    class="uk-modal-full"
  >
    <div class="uk-modal-dialog uk-modal-body uk-margin-small">
      <div class="uk-modal-title uk-padding-small">
        Preview PDF {{ blobPdf.name }}
        <button
          class="uk-modal-close-full"
          type="button"
          uk-close
        />
      </div>
      <iframe :src="blobPdf.file" />
    </div>
  </div>
</template>

<script>

export default {
  props : {
    blobPdf: {
      required: true,
      type: Object
    },
    targetField:{
      required: true,
      type: String
    }
  }
}

</script>

<style scoped>
  
  .uk-modal-dialog {
    min-height: 100vh;
  }

  object, iframe {
    border: 3px solid #cecece;
    background: #e9e9e9;
    width: 100%;
    height: 100vh;
    overflow: scroll;
  }

</style>
