<template>
  <div
    class="uk-child-width-expand@s uk-grid-small"
    uk-grid
  >
    <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-2-5@l uk-width-2-5@xl uk-margin-auto-vertical uk-text-left">
      <span>{{ title.replace('*','') }}</span>
      <span
        v-if="title.includes('*')"
        class="uk-text-danger"
      >*</span>
    </div>
    <div
      class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-3-5@l uk-width-3-5@xl js-upload"
      uk-form-custom
    >
      <slot />
    </div>
  </div>
</template>

<script>
export default {
  props: {
    title: {
      required: true,
      type: String
    }
  },
  data(){
    return {
      isSav: this.$route.fullPath.includes("submit-and-verification")
    }
  }
}
</script>
