<template>
  <div
    id="modal-verify"
    uk-modal
    esc-close="false"
    bg-close="false"
    container="#container-modal-reason"
  >
    <div class="uk-modal-dialog uk-modal-body">
      <div class="wrapper-content">
        <h3 class="uk-text-center label-green">
          Verifikasi Dokumen
        </h3>
        <p class="uk-text-center">
          Apakah dokumen ini sudah sesuai?
        </p>
        <div
          class="uk-child-width-expand@s uk-grid-small uk-margin-top"
          uk-grid
        >
          <div class="uk-width-1-1 uk-text-center">
            <button
              class="uk-button uk-button-default uk-width-expand uk-width-auto@s"
              type="button"
              @click="abort"
            >
              <img
                v-lazy="`${images}/icon/close.svg`"
                alt=""
                class="uk-margin-small-right"
              >
              Batal
            </button>
            <button
              class="uk-button uk-button-primary uk-margin-top uk-margin-remove-top-med uk-margin-remove-left@s uk-margin-left-med uk-width-expand uk-width-auto@s"
              type="button"
              :disabled="isLoading"
              @click="verifyDocumentSubmit"
            >
              <span
                v-if="isLoading"
                uk-spinner
                class="button-spinner"
              />
              Ya, Verifikasi
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  notificationSuccess
} from '@/utils/notification'
import { mapActions, mapGetters } from 'vuex'
import { PREFIX_IMAGE } from '@/utils/constant'

export default {
  data(){
    return {
      isLoading: false,
      images: PREFIX_IMAGE
    }
  },
  computed: {
    ...mapGetters({
      verifiedStatus: 'acquisition/dataVerifyDocument'
    })
  },
  methods: {
    ...mapActions({
      verifyDocument: 'acquisition/verifyDocument'
    }),
    async verifyDocumentSubmit(){
      this.isLoading = true
      await this.verifyDocument(`${this.$route.params.id}`)
      if(this.verifiedStatus){
        notificationSuccess('Berhasil Verifikasi Dokumen!')
        window.UIkit.modal('#modal-verify').hide()
        setTimeout(() => {
          this.$router.go()
        }, 1000)
      }
    },
    abort(){
      this.isLoading = false
      window.UIkit.modal('#modal-verify').hide()
    }
  }
}
</script>
