<template>
  <div class="uk-container uk-container-expand card-scrollable">
    <div
      v-if="pathName.includes('submit-and-verification')"
      class="uk-margin-top uk-margin-bottom"
      uk-grid
    >
      <div class="uk-width-2-3@s font-header">
        {{ dataHeader.title }}
      </div>
      <div
        v-if="dataHeader.isBreadcrumb"
        class="uk-width-1-3@s"
      >
        <bread-crumb :bread-crumb="dataHeader.breadcrumb" />
      </div>
    </div>
    <div class="uk-margin-bottom">
      <div class="uk-card uk-card-default uk-padding-small kandang-card">
        <div
          class="uk-child-width-expand@s uk-grid-small"
          uk-grid
        >
          <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-1-2@l uk-width-1-2@xl">
            <WrapperInput :title="'KTP Pemohon*'">
              <div
                v-if="editMode || pathName.includes('submit-and-verification')"
              >
                <input
                  ref="identity_card_applicant"
                  v-validate="{ 'required': formData.identity_card_applicant ? false : true }"
                  name="identity_card_applicant"
                  class="{'uk-form-danger': errors.has('identity_card_applicant')}"
                  accept="image/png,image/jpg,image/jpeg,application/pdf"
                  type="file"
                  multiple
                  :disabled="!editMode"
                  @change="(event) => handleUploadFile(event, 'identity_card_applicant')"
                >
                <div 
                  class="uk-inline"
                  tabindex="-1"
                >
                  <div
                    class="uk-input"
                  >
                    <span
                      style="max-width: 90%;"
                      class="uk-panel uk-text-truncate"
                    >
                      {{ formData.identity_card_applicant ? formData.identity_card_applicant.name : "Unggah Dokumen" }}
                    </span>
                    <img
                      v-lazy="formData.identity_card_applicant ? `${images}/icon/close-green.svg` : `${images}/icon/logout.svg`"
                      alt="icon action"
                      class="uk-form-icon uk-form-icon-flip icon-upload"
                      :class="formData.identity_card_applicant ? 'uk-form-icon-click' : ''"
                      uk-icon
                      @click="handleDelete('identity_card_applicant')"
                    >
                  </div>
                  <div
                    v-show="errors.has('identity_card_applicant')"
                    class="uk-text-small uk-text-danger"
                  >
                    {{ errors.first('identity_card_applicant') }}
                  </div>
                </div>
              </div>
              <div v-else>
                <div
                  class="uk-width-1-1 uk-flex uk-flex-between"
                  tabindex="2"
                  :class="`${!isEdit ? 'uk-input-disabled' : 'uk-input'}`"
                >
                  <span class="uk-panel uk-text-truncate">
                    {{ formData.identity_card_applicant ? formData.identity_card_applicant.name :'-' }}
                  </span>
                  <img
                    v-if="formData.identity_card_applicant "
                    v-lazy="`${images}/icon/eye-line.svg`"
                    alt="icon action"
                    class="icon-upload"
                    @click="handlePreviewImage('identity_card_applicant')"
                  >
                </div>
              </div>
            </WrapperInput>
            <div
              class="uk-child-width-expand@s uk-grid-small"
              uk-grid
            >
              <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-2-5@l uk-width-2-5@xl uk-margin-auto-vertical">
                NIK Pemohon<span class="uk-text-danger">*</span>
              </div>
              <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-3-5@l uk-width-3-5@xl">
                <input
                  v-model="formData.id_number_applicant"
                  v-validate="{ required: true, regex: '^([0-9]+)$', max: '16' }"
                  class="uk-input"
                  type="text"
                  :maxlength="16"
                  :minlength="16"
                  name="id_number_applicant"
                  placeholder="Masukkan NIK Pemohon"
                  :class="{'uk-form-danger': errors.has('id_number_applicant')}"
                  :disabled="!editMode"
                >
                <div
                  v-show="errors.has('id_number_applicant')"
                  class="uk-text-small uk-text-danger"
                >
                  {{ errors.first('id_number_applicant') }}
                </div>
              </div>
            </div>
            <WrapperInput :title="'KTP Pasangan'">
              <div
                v-if="editMode || pathName.includes('submit-and-verification')"
              >
                <input
                  ref="identity_card_partner"
                  v-validate="{ 'required':false }"
                  name="identity_card_partner"
                  class="{'uk-form-danger': errors.has('identity_card_partner')}"
                  accept="image/png,image/jpg,image/jpeg,application/pdf"
                  type="file"
                  multiple
                  :disabled="!editMode"
                  @change="(event) => handleUploadFile(event, 'identity_card_partner')"
                >
                <div 
                  class="uk-inline"
                  tabindex="-1"
                >
                  <div
                    class="uk-input"
                  >
                    <span
                      style="max-width: 90%;"
                      class="uk-panel uk-text-truncate"
                    >
                      {{ formData.identity_card_partner ? formData.identity_card_partner.name : "Unggah Dokumen" }}
                    </span>
                    <img
                      v-lazy="formData.identity_card_partner ? `${images}/icon/close-green.svg` : `${images}/icon/logout.svg`"
                      alt="icon action"
                      class="uk-form-icon uk-form-icon-flip icon-upload"
                      :class="formData.identity_card_partner ? 'uk-form-icon-click' : ''"
                      uk-icon
                      @click="handleDelete('identity_card_partner')"
                    >
                  </div>
                  <div
                    v-show="errors.has('identity_card_partner')"
                    class="uk-text-small uk-text-danger"
                  >
                    {{ errors.first('identity_card_partner') }}
                  </div>
                </div>
              </div>
              <div v-else>
                <div
                  class="uk-width-1-1 uk-flex uk-flex-between"
                  tabindex="2"
                  :class="`${!isEdit ? 'uk-input-disabled' : 'uk-input'}`"
                >
                  <span class="uk-panel uk-text-truncate">
                    {{ formData.identity_card_partner ? formData.identity_card_partner.name : '-' }}
                  </span>
                  <img
                    v-if="formData.identity_card_partner"
                    v-lazy="`${images}/icon/eye-line.svg`"
                    alt="icon action"
                    class="icon-upload"
                    @click="handlePreviewImage('identity_card_partner')"
                  >
                </div>
              </div>
            </WrapperInput>
            <div
              class="uk-child-width-expand@s uk-grid-small"
              uk-grid
            >
              <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-2-5@l uk-width-2-5@xl uk-margin-auto-vertical">
                NIK Pasangan
              </div>
              <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-3-5@l uk-width-3-5@xl">
                <input
                  v-model="formData.id_number_partner"
                  v-validate="{ required: false, regex: '^([0-9]+)$', max: '16' }"
                  class="uk-input"
                  type="text"
                  :maxlength="16"
                  :minlength="16"
                  name="id_number_partner"
                  placeholder="Masukkan NIK Pasangan"
                  :class="{'uk-form-danger': errors.has('id_number_partner')}"
                  :disabled="!editMode"
                >
                <div
                  v-show="errors.has('id_number_partner')"
                  class="uk-text-small uk-text-danger"
                >
                  {{ errors.first('id_number_partner') }}
                </div>
              </div>
            </div>
            <WrapperInput :title="'Kartu Keluarga*'">
              <div
                v-if="editMode || pathName.includes('submit-and-verification')"
              >
                <input
                  ref="family_card"  
                  v-validate="{ 'required': formData.family_card ? false : true }"
                  name="family_card"
                  class="{'uk-form-danger': errors.has('family_card')}"
                  accept="image/png,image/jpg,image/jpeg,application/pdf"
                  type="file"
                  multiple
                  :disabled="!editMode"
                  @change="(event) => handleUploadFile(event, 'family_card')"
                >
                <div 
                  class="uk-inline"
                  tabindex="-1"
                >
                  <div
                    class="uk-input"
                  >
                    <span
                      style="max-width: 90%;"
                      class="uk-panel uk-text-truncate"
                    >
                      {{ formData.family_card ? formData.family_card.name : "Unggah Dokumen" }}
                    </span>
                    <img
                      v-lazy="formData.family_card ? `${images}/icon/close-green.svg` : `${images}/icon/logout.svg`"
                      alt="icon action"
                      class="uk-form-icon uk-form-icon-flip icon-upload"
                      :class="formData.family_card ? 'uk-form-icon-click' : ''"
                      uk-icon
                      @click="handleDelete('family_card')"
                    >
                  </div>
                  <div
                    v-show="errors.has('family_card')"
                    class="uk-text-small uk-text-danger"
                  >
                    {{ errors.first('family_card') }}
                  </div>
                </div>
              </div>
              <div v-else>
                <div
                  class="uk-width-1-1 uk-flex uk-flex-between"
                  tabindex="2"
                  :class="`${!isEdit ? 'uk-input-disabled' : 'uk-input'}`"
                >
                  <span class="uk-panel uk-text-truncate">
                    {{ formData.family_card ? formData.family_card.name : '-' }}
                  </span>
                  <img
                    v-if="formData.family_card"
                    v-lazy="`${images}/icon/eye-line.svg`"
                    alt="icon action"
                    class="icon-upload"
                    @click="handlePreviewImage('family_card')"
                  >
                </div>
              </div>
            </WrapperInput>
            <WrapperInput :title="'Pemilik Nomor Rekening*'">
              <div>
                <multiselect
                  id="account_bank_owner"
                  v-model="formData.account_bank_owner"
                  v-validate="{'required': true}"
                  label="name" 
                  name="account_bank_owner" 
                  track-by="name" 
                  placeholder="Pilih" 
                  open-direction="bottom"
                  :options="accountOwnerList"
                  :searchable="true"
                  :close-on-select="true" 
                  :max-height="500"
                  :show-no-results="true"
                  :disabled="!editMode"
                >
                  <span slot="noResult">Oops! Data tidak ditemukan.</span>
                </multiselect>
                <div
                  v-show="errors.has('account_bank_owner')"
                  class="uk-text-small uk-text-danger"
                >
                  {{ errors.first('account_bank_owner') }}
                </div>
              </div>
            </WrapperInput>
            <WrapperInput 
              v-if="accountOwnerSelected"
              :title="'Nomor Rekening*'"
            >
              <input
                v-model="formData.account_number_bank"
                v-validate="{ required: formData.account_number_bank ? false : true, regex: '^([0-9]+)$' }"
                class="uk-input"
                type="text"
                name="account_number_bank"
                placeholder="Masukkan Nomor Rekening"
                :class="{'uk-form-danger': errors.has('account_number_bank')}"
                :disabled="!editMode"
              >
              <div
                v-show="errors.has('account_number_bank')"
                class="uk-text-small uk-text-danger"
              >
                {{ errors.first('account_number_bank') }}
              </div>
            </WrapperInput>
            <WrapperInput 
              v-if="accountOwnerSelected"
              :title="'Foto Buku Rekening*'"
            >
              <div
                v-if="editMode || pathName.includes('submit-and-verification')"
              >
                <input
                  ref="account_bank_book"  
                  v-validate="{ 'required': formData.account_bank_book ? false : true }"
                  name="account_bank_book"
                  class="{'uk-form-danger': errors.has('account_bank_book')}"
                  accept="image/png,image/jpg,image/jpeg,application/pdf"
                  type="file"
                  multiple
                  :disabled="!editMode"
                  @change="(event) => handleUploadFile(event, 'account_bank_book')"
                >
                <div 
                  class="uk-inline"
                  tabindex="-1"
                >
                  <div
                    class="uk-input"
                  >
                    <span
                      style="max-width: 90%;"
                      class="uk-panel uk-text-truncate"
                    >
                      {{ formData.account_bank_book ? formData.account_bank_book.name : "Unggah Dokumen" }}
                    </span>
                    <img
                      v-lazy="formData.account_bank_book ? `${images}/icon/close-green.svg` : `${images}/icon/logout.svg`"
                      alt="icon action"
                      class="uk-form-icon uk-form-icon-flip icon-upload"
                      :class="formData.account_bank_book ? 'uk-form-icon-click' : ''"
                      uk-icon
                      @click="handleDelete('account_bank_book')"
                    >
                  </div>
                  <div
                    v-show="errors.has('account_bank_book')"
                    class="uk-text-small uk-text-danger"
                  >
                    {{ errors.first('account_bank_book') }}
                  </div>
                </div>
              </div>
              <div v-else>
                <div
                  class="uk-width-1-1 uk-flex uk-flex-between"
                  tabindex="2"
                  :class="`${!isEdit ? 'uk-input-disabled' : 'uk-input'}`"
                >
                  <span class="uk-panel uk-text-truncate">
                    {{ formData.account_bank_book ? formData.account_bank_book.name : '-' }}
                  </span>
                  <img
                    v-if="formData.account_bank_book"
                    v-lazy="`${images}/icon/eye-line.svg`"
                    alt="icon action"
                    class="icon-upload"
                    @click="handlePreviewImage('account_bank_book')"
                  >
                </div>
              </div>
            </WrapperInput>
            <WrapperInput 
              v-if="accountOwnerSelected && !isTheOwner"
              :title="'Kartu Keluarga Pemilik Rekening*'"
            > 
              <div
                v-if="editMode || pathName.includes('submit-and-verification')"
              >
                <input
                  ref="family_card_account_bank_owner"  
                  v-validate="{ 'required': formData.family_card_account_bank_owner ? false : true }"
                  name="family_card_account_bank_owner"
                  class="{'uk-form-danger': errors.has('family_card_account_bank_owner')}"
                  accept="image/png,image/jpg,image/jpeg,application/pdf"
                  type="file"
                  multiple
                  :disabled="!editMode"
                  @change="(event) => handleUploadFile(event, 'family_card_account_bank_owner')"
                >
                <div 
                  class="uk-inline"
                  tabindex="-1"
                >
                  <div
                    class="uk-input"
                  >
                    <span
                      style="max-width: 90%;"
                      class="uk-panel uk-text-truncate"
                    >
                      {{ formData.family_card_account_bank_owner ? formData.family_card_account_bank_owner.name : "Unggah Dokumen" }}
                    </span>
                    <img
                      v-lazy="formData.family_card_account_bank_owner ? `${images}/icon/close-green.svg` : `${images}/icon/logout.svg`"
                      alt="icon action"
                      class="uk-form-icon uk-form-icon-flip icon-upload"
                      :class="formData.family_card_account_bank_owner ? 'uk-form-icon-click' : ''"
                      uk-icon
                      @click="handleDelete('family_card_account_bank_owner')"
                    >
                  </div>
                  <div
                    v-show="errors.has('family_card_account_bank_owner')"
                    class="uk-text-small uk-text-danger"
                  >
                    {{ errors.first('family_card_account_bank_owner') }}
                  </div>
                </div>
              </div>
              <div v-else>
                <div
                  class="uk-width-1-1 uk-flex uk-flex-between"
                  tabindex="2"
                  :class="`${!isEdit ? 'uk-input-disabled' : 'uk-input'}`"
                >
                  <span class="uk-panel uk-text-truncate">
                    {{ formData.family_card_account_bank_owner ? formData.family_card_account_bank_owner.name : '-' }}
                  </span>
                  <img
                    v-if="formData.family_card_account_bank_owner"
                    v-lazy="`${images}/icon/eye-line.svg`"
                    alt="icon action"
                    class="icon-upload"
                    @click="handlePreviewImage('family_card_account_bank_owner')"
                  >
                </div>
              </div>
            </WrapperInput>
            <WrapperInput :title="'Pernyataan Rekening RHPP*'">
              <div
                v-if="editMode || pathName.includes('submit-and-verification')"
              >
                <input
                  ref="rhpp_account_statement"  
                  v-validate="{ 'required': formData.rhpp_account_statement ? false : true }"
                  name="rhpp_account_statement"
                  class="{'uk-form-danger': errors.has('rhpp_account_statement')}"
                  accept="image/png,image/jpg,image/jpeg,application/pdf"
                  type="file"
                  multiple
                  :disabled="!editMode"
                  @change="(event) => handleUploadFile(event, 'rhpp_account_statement')"
                >
                <div 
                  class="uk-inline"
                  tabindex="-1"
                >
                  <div
                    class="uk-input"
                  >
                    <span
                      style="max-width: 90%;"
                      class="uk-panel uk-text-truncate"
                    >
                      {{ formData.rhpp_account_statement ? formData.rhpp_account_statement.name : "Unggah Dokumen" }}
                    </span>
                    <img
                      v-lazy="formData.rhpp_account_statement ? `${images}/icon/close-green.svg` : `${images}/icon/logout.svg`"
                      alt="icon action"
                      class="uk-form-icon uk-form-icon-flip icon-upload"
                      :class="formData.rhpp_account_statement ? 'uk-form-icon-click' : ''"
                      uk-icon
                      @click="handleDelete('rhpp_account_statement')"
                    >
                  </div>
                  <div
                    v-show="errors.has('rhpp_account_statement')"
                    class="uk-text-small uk-text-danger"
                  >
                    {{ errors.first('rhpp_account_statement') }}
                  </div>
                </div>
              </div>
              <div v-else>
                <div
                  class="uk-width-1-1 uk-flex uk-flex-between"
                  tabindex="2"
                  :class="`${!isEdit ? 'uk-input-disabled' : 'uk-input'}`"
                >
                  <span class="uk-panel uk-text-truncate">
                    {{ formData.rhpp_account_statement ? formData.rhpp_account_statement.name : '-' }}
                  </span>
                  <img
                    v-if="formData.rhpp_account_statement"
                    v-lazy="`${images}/icon/eye-line.svg`"
                    alt="icon action"
                    class="icon-upload"
                    @click="handlePreviewImage('rhpp_account_statement')"
                  >
                </div>
              </div>
            </WrapperInput>
            <WrapperInput :title="'NPWP'">
              <div
                v-if="editMode || pathName.includes('submit-and-verification')"
              >
                <input
                  ref="npwp"  
                  v-validate="{ 'required':false }"
                  name="npwp"
                  class="{'uk-form-danger': errors.has('npwp')}"
                  accept="image/png,image/jpg,image/jpeg,application/pdf"
                  type="file"
                  multiple
                  :disabled="!editMode"
                  @change="(event) => handleUploadFile(event, 'npwp')"
                >
                <div 
                  class="uk-inline"
                  tabindex="-1"
                >
                  <div
                    class="uk-input"
                  >
                    <span
                      style="max-width: 90%;"
                      class="uk-panel uk-text-truncate"
                    >
                      {{ formData.npwp ? formData.npwp.name : "Unggah Dokumen" }}
                    </span>
                    <img
                      v-lazy="formData.npwp ? `${images}/icon/close-green.svg` : `${images}/icon/logout.svg`"
                      alt="icon action"
                      class="uk-form-icon uk-form-icon-flip icon-upload"
                      :class="formData.npwp ? 'uk-form-icon-click' : ''"
                      uk-icon
                      @click="handleDelete('npwp')"
                    >
                  </div>
                  <div
                    v-show="errors.has('npwp')"
                    class="uk-text-small uk-text-danger"
                  >
                    {{ errors.first('npwp') }}
                  </div>
                </div>
              </div>
              <div v-else>
                <div
                  class="uk-width-1-1 uk-flex uk-flex-between"
                  tabindex="2"
                  :class="`${!isEdit ? 'uk-input-disabled' : 'uk-input'}`"
                >
                  <span class="uk-panel uk-text-truncate">
                    {{ formData.npwp ? formData.npwp.name : '-' }}
                  </span>
                  <img
                    v-if="formData.npwp"
                    v-lazy="`${images}/icon/eye-line.svg`"
                    alt="icon action"
                    class="icon-upload"
                    @click="handlePreviewImage('npwp')"
                  >
                </div>
              </div>
            </WrapperInput>
            <WrapperInput :title="'Izin Lingkungan Dan Tanda Tangan Warga*'">
              <div
                v-if="editMode || pathName.includes('submit-and-verification')"
              >
                <input
                  ref="allow_environment"  
                  v-validate="{ 'required': formData.allow_environment ? false : true }"
                  name="allow_environment"
                  class="{'uk-form-danger': errors.has('allow_environment')}"
                  accept="image/png,image/jpg,image/jpeg,application/pdf"
                  type="file"
                  multiple
                  :disabled="!editMode"
                  @change="(event) => handleUploadFile(event, 'allow_environment')"
                >
                <div 
                  class="uk-inline"
                  tabindex="-1"
                >
                  <div
                    class="uk-input"
                  >
                    <span
                      style="max-width: 90%;"
                      class="uk-panel uk-text-truncate"
                    >
                      {{ formData.allow_environment ? formData.allow_environment.name : "Unggah Dokumen" }}
                    </span>
                    <img
                      v-lazy="formData.allow_environment ? `${images}/icon/close-green.svg` : `${images}/icon/logout.svg`"
                      alt="icon action"
                      class="uk-form-icon uk-form-icon-flip icon-upload"
                      :class="formData.allow_environment ? 'uk-form-icon-click' : ''"
                      uk-icon
                      @click="handleDelete('allow_environment')"
                    >
                  </div>
                  <div
                    v-show="errors.has('allow_environment')"
                    class="uk-text-small uk-text-danger"
                  >
                    {{ errors.first('allow_environment') }}
                  </div>
                </div>
              </div>
              <div v-else>
                <div
                  class="uk-width-1-1 uk-flex uk-flex-between"
                  tabindex="2"
                  :class="`${!isEdit ? 'uk-input-disabled' : 'uk-input'}`"
                >
                  <span class="uk-panel uk-text-truncate">
                    {{ formData.allow_environment ? formData.allow_environment.name : '-' }}
                  </span>
                  <img
                    v-if="formData.allow_environment"
                    v-lazy="`${images}/icon/eye-line.svg`"
                    alt="icon action"
                    class="icon-upload"
                    @click="handlePreviewImage('allow_environment')"
                  >
                </div>
              </div>
            </WrapperInput>
          </div>
          <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-1-2@l uk-width-1-2@xl">
            <WrapperInput :title="'SKU Desa*'">
              <div
                v-if="editMode || pathName.includes('submit-and-verification')"
              >
                <input
                  ref="sku_village"  
                  v-validate="{ 'required': formData.sku_village ? false : true }"
                  name="sku_village"
                  class="{'uk-form-danger': errors.has('sku_village')}"
                  accept="image/png,image/jpg,image/jpeg,application/pdf"
                  type="file"
                  multiple
                  :disabled="!editMode"
                  @change="(event) => handleUploadFile(event, 'sku_village')"
                >
                <div 
                  class="uk-inline"
                  tabindex="-1"
                >
                  <div
                    class="uk-input"
                  >
                    <span
                      style="max-width: 90%;"
                      class="uk-panel uk-text-truncate"
                    >
                      {{ formData.sku_village ? formData.sku_village.name : "Unggah Dokumen" }}
                    </span>
                    <img
                      v-lazy="formData.sku_village ? `${images}/icon/close-green.svg` : `${images}/icon/logout.svg`"
                      alt="icon action"
                      class="uk-form-icon uk-form-icon-flip icon-upload"
                      :class="formData.sku_village ? 'uk-form-icon-click' : ''"
                      uk-icon
                      @click="handleDelete('sku_village')"
                    >
                  </div>
                  <div
                    v-show="errors.has('sku_village')"
                    class="uk-text-small uk-text-danger"
                  >
                    {{ errors.first('sku_village') }}
                  </div>
                </div>
              </div>
              <div v-else>
                <div
                  class="uk-width-1-1 uk-flex uk-flex-between"
                  tabindex="2"
                  :class="`${!isEdit ? 'uk-input-disabled' : 'uk-input'}`"
                >
                  <span class="uk-panel uk-text-truncate">
                    {{ formData.sku_village ? formData.sku_village.name : '-' }}
                  </span>
                  <img
                    v-if="formData.sku_village"
                    v-lazy="`${images}/icon/eye-line.svg`"
                    alt="icon action"
                    class="icon-upload"
                    @click="handlePreviewImage('sku_village')"
                  >
                </div>
              </div>
            </WrapperInput>
            <WrapperInput :title="'SKU Domisili*'">
              <div
                v-if="editMode || pathName.includes('submit-and-verification')"
              >
                <input
                  ref="sku_domicile"  
                  v-validate="{ 'required': formData.sku_domicile ? false : true }"
                  name="sku_domicile"
                  class="{'uk-form-danger': errors.has('sku_domicile')}"
                  accept="image/png,image/jpg,image/jpeg,application/pdf"
                  type="file"
                  multiple
                  :disabled="!editMode"
                  @change="(event) => handleUploadFile(event, 'sku_domicile')"
                >
                <div 
                  class="uk-inline"
                  tabindex="-1"
                >
                  <div
                    class="uk-input"
                  >
                    <span
                      style="max-width: 90%;"
                      class="uk-panel uk-text-truncate"
                    >
                      {{ formData.sku_domicile ? formData.sku_domicile.name : "Unggah Dokumen" }}
                    </span>
                    <img
                      v-lazy="formData.sku_domicile ? `${images}/icon/close-green.svg` : `${images}/icon/logout.svg`"
                      alt="icon action"
                      class="uk-form-icon uk-form-icon-flip icon-upload"
                      :class="formData.sku_domicile ? 'uk-form-icon-click' : ''"
                      uk-icon
                      @click="handleDelete('sku_domicile')"
                    >
                  </div>
                  <div
                    v-show="errors.has('sku_domicile')"
                    class="uk-text-small uk-text-danger"
                  >
                    {{ errors.first('sku_domicile') }}
                  </div>
                </div>
              </div>
              <div v-else>
                <div
                  class="uk-width-1-1 uk-flex uk-flex-between"
                  tabindex="2"
                  :class="`${!isEdit ? 'uk-input-disabled' : 'uk-input'}`"
                >
                  <span class="uk-panel uk-text-truncate">
                    {{ formData.sku_domicile ? formData.sku_domicile.name : '-' }}
                  </span>
                  <img
                    v-if="formData.sku_domicile"
                    v-lazy="`${images}/icon/eye-line.svg`"
                    alt="icon action"
                    class="icon-upload"
                    @click="handlePreviewImage('sku_domicile')"
                  >
                </div>
              </div>
            </WrapperInput>
            <WrapperInput :title="'SPT Lahan Yang Digunakan'">
              <div
                v-if="editMode || pathName.includes('submit-and-verification')"
              >
                <input
                  ref="spt_land_used"
                  v-validate="{ 'required':false }"
                  name="spt_land_used"
                  class="{'uk-form-danger': errors.has('spt_land_used')}"
                  accept="image/png,image/jpg,image/jpeg,application/pdf"
                  type="file"
                  multiple
                  :disabled="!editMode"
                  @change="(event) => handleUploadFile(event, 'spt_land_used')"
                >
                <div 
                  class="uk-inline"
                  tabindex="-1"
                >
                  <div
                    class="uk-input"
                  >
                    <span
                      style="max-width: 90%;"
                      class="uk-panel uk-text-truncate"
                    >
                      {{ formData.spt_land_used ? formData.spt_land_used.name : "Unggah Dokumen" }}
                    </span>
                    <img
                      v-lazy="formData.spt_land_used ? `${images}/icon/close-green.svg` : `${images}/icon/logout.svg`"
                      alt="icon action"
                      class="uk-form-icon uk-form-icon-flip icon-upload"
                      :class="formData.spt_land_used ? 'uk-form-icon-click' : ''"
                      uk-icon
                      @click="handleDelete('spt_land_used')"
                    >
                  </div>
                  <div
                    v-show="errors.has('spt_land_used')"
                    class="uk-text-small uk-text-danger"
                  >
                    {{ errors.first('spt_land_used') }}
                  </div>
                </div>
              </div>
              <div v-else>
                <div
                  class="uk-width-1-1 uk-flex uk-flex-between"
                  tabindex="2"
                  :class="`${!isEdit ? 'uk-input-disabled' : 'uk-input'}`"
                >
                  <span class="uk-panel uk-text-truncate">
                    {{ formData.spt_land_used ? formData.spt_land_used.name : '-' }}
                  </span>
                  <img
                    v-if="formData.spt_land_used"
                    v-lazy="`${images}/icon/eye-line.svg`"
                    alt="icon action"
                    class="icon-upload"
                    @click="handlePreviewImage('spt_land_used')"
                  >
                </div>
              </div>
            </WrapperInput>
            <WrapperInput :title="'SK Penggunaan Lahan'">
              <div
                v-if="editMode || pathName.includes('submit-and-verification')"
              >
                <input
                  ref="sk_land_used"
                  v-validate="{ 'required':false }"
                  name="sk_land_used"
                  class="{'uk-form-danger': errors.has('sk_land_used')}"
                  accept="image/png,image/jpg,image/jpeg,application/pdf"
                  type="file"
                  multiple
                  :disabled="!editMode"
                  @change="(event) => handleUploadFile(event, 'sk_land_used')"
                >
                <div 
                  class="uk-inline"
                  tabindex="-1"
                >
                  <div
                    class="uk-input"
                  >
                    <span
                      style="max-width: 90%;"
                      class="uk-panel uk-text-truncate"
                    >
                      {{ formData.sk_land_used ? formData.sk_land_used.name : "Unggah Dokumen" }}
                    </span>
                    <img
                      v-lazy="formData.sk_land_used ? `${images}/icon/close-green.svg` : `${images}/icon/logout.svg`"
                      alt="icon action"
                      class="uk-form-icon uk-form-icon-flip icon-upload"
                      :class="formData.sk_land_used ? 'uk-form-icon-click' : ''"
                      uk-icon
                      @click="handleDelete('sk_land_used')"
                    >
                  </div>
                  <div
                    v-show="errors.has('sk_land_used')"
                    class="uk-text-small uk-text-danger"
                  >
                    {{ errors.first('sk_land_used') }}
                  </div>
                </div>
              </div>
              <div v-else>
                <div
                  class="uk-width-1-1 uk-flex uk-flex-between"
                  tabindex="2"
                  :class="`${!isEdit ? 'uk-input-disabled' : 'uk-input'}`"
                >
                  <span class="uk-panel uk-text-truncate">
                    {{ formData.sk_land_used ? formData.sk_land_used.name : '-' }}
                  </span>
                  <img
                    v-if="formData.sk_land_used"
                    v-lazy="`${images}/icon/eye-line.svg`"
                    alt="icon action"
                    class="icon-upload"
                    @click="handlePreviewImage('sk_land_used')"
                  >
                </div>
              </div>
            </WrapperInput>
            <WrapperInput
              v-if="!landStatus.toLowerCase().includes('sendiri')"
              :title="'KTP Pemberi Kuasa Lahan*'"
            >
              <div
                v-if="editMode || pathName.includes('submit-and-verification')"
              >
                <input
                  ref="copy_author_id_card"  
                  v-validate="{ 'required': formData.copy_author_id_card ? false : true }"
                  name="copy_author_id_card"
                  class="{'uk-form-danger': errors.has('copy_author_id_card')}"
                  accept="image/png,image/jpg,image/jpeg,application/pdf"
                  type="file"
                  multiple
                  :disabled="!editMode"
                  @change="(event) => handleUploadFile(event, 'copy_author_id_card')"
                >
                <div 
                  class="uk-inline"
                  tabindex="-1"
                >
                  <div
                    class="uk-input"
                  >
                    <span
                      style="max-width: 90%;"
                      class="uk-panel uk-text-truncate"
                    >
                      {{ formData.copy_author_id_card ? formData.copy_author_id_card.name : "Unggah Dokumen" }}
                    </span>
                    <img
                      v-lazy="formData.copy_author_id_card ? `${images}/icon/close-green.svg` : `${images}/icon/logout.svg`"
                      alt="icon action"
                      class="uk-form-icon uk-form-icon-flip icon-upload"
                      :class="formData.copy_author_id_card ? 'uk-form-icon-click' : ''"
                      uk-icon
                      @click="handleDelete('copy_author_id_card')"
                    >
                  </div>
                  <div
                    v-show="errors.has('copy_author_id_card')"
                    class="uk-text-small uk-text-danger"
                  >
                    {{ errors.first('copy_author_id_card') }}
                  </div>
                </div>
              </div>
              <div v-else>
                <div
                  class="uk-width-1-1 uk-flex uk-flex-between"
                  tabindex="2"
                  :class="`${!isEdit ? 'uk-input-disabled' : 'uk-input'}`"
                >
                  <span class="uk-panel uk-text-truncate">
                    {{ formData.copy_author_id_card ? formData.copy_author_id_card.name : '-' }}
                  </span>
                  <img
                    v-if="formData.copy_author_id_card "
                    v-lazy="`${images}/icon/eye-line.svg`"
                    alt="icon action"
                    class="icon-upload"
                    @click="handlePreviewImage('copy_author_id_card')"
                  >
                </div>
              </div>
            </WrapperInput>
            <WrapperInput :title="'Form Wawancara Mitra'">
              <div
                v-if="editMode || pathName.includes('submit-and-verification')"
              >
                <input
                  ref="partner_interview_form"
                  v-validate="{ 'required':false }"
                  name="partner_interview_form"
                  class="{'uk-form-danger': errors.has('partner_interview_form')}"
                  accept="image/png,image/jpg,image/jpeg,application/pdf"
                  type="file"
                  multiple
                  :disabled="!editMode"
                  @change="(event) => handleUploadFile(event, 'partner_interview_form')"
                >
                <div 
                  class="uk-inline"
                  tabindex="-1"
                >
                  <div
                    class="uk-input"
                  >
                    <span
                      style="max-width: 90%;"
                      class="uk-panel uk-text-truncate"
                    >
                      {{ formData.partner_interview_form ? formData.partner_interview_form.name : "Unggah Dokumen" }}
                    </span>
                    <img
                      v-lazy="formData.partner_interview_form ? `${images}/icon/close-green.svg` : `${images}/icon/logout.svg`"
                      alt="icon action"
                      class="uk-form-icon uk-form-icon-flip icon-upload"
                      :class="formData.partner_interview_form ? 'uk-form-icon-click' : ''"
                      uk-icon
                      @click="handleDelete('partner_interview_form')"
                    >
                  </div>
                  <div
                    v-show="errors.has('partner_interview_form')"
                    class="uk-text-small uk-text-danger"
                  >
                    {{ errors.first('partner_interview_form') }}
                  </div>
                </div>
              </div>
              <div v-else>
                <div
                  class="uk-width-1-1 uk-flex uk-flex-between"
                  tabindex="2"
                  :class="`${!isEdit ? 'uk-input-disabled' : 'uk-input'}`"
                >
                  <span class="uk-panel uk-text-truncate">
                    {{ formData.partner_interview_form ? formData.partner_interview_form.name : '-' }}
                  </span>
                  <img
                    v-if="formData.partner_interview_form "
                    v-lazy="`${images}/icon/eye-line.svg`"
                    alt="icon action"
                    class="icon-upload"
                    @click="handlePreviewImage('partner_interview_form')"
                  >
                </div>
              </div>
            </WrapperInput>
            <WrapperInput :title="'Kepemilikan Tanah'">
              <div
                v-if="editMode || pathName.includes('submit-and-verification')"
              >
                <input
                  ref="land_ownership"
                  v-validate="{ 'required':false }"
                  name="land_ownership"
                  class="{'uk-form-danger': errors.has('land_ownership')}"
                  accept="image/png,image/jpg,image/jpeg,application/pdf"
                  type="file"
                  multiple
                  :disabled="!editMode"
                  @change="(event) => handleUploadFile(event, 'land_ownership')"
                >
                <div 
                  class="uk-inline"
                  tabindex="-1"
                >
                  <div
                    class="uk-input"
                  >
                    <span
                      style="max-width: 90%;"
                      class="uk-panel uk-text-truncate"
                    >
                      {{ formData.land_ownership ? formData.land_ownership.name : "Unggah Dokumen" }}
                    </span>
                    <img
                      v-lazy="formData.land_ownership ? `${images}/icon/close-green.svg` : `${images}/icon/logout.svg`"
                      alt="icon action"
                      class="uk-form-icon uk-form-icon-flip icon-upload"
                      :class="formData.land_ownership ? 'uk-form-icon-click' : ''"
                      uk-icon
                      @click="handleDelete('land_ownership')"
                    >
                  </div>
                  <div
                    v-show="errors.has('land_ownership')"
                    class="uk-text-small uk-text-danger"
                  >
                    {{ errors.first('land_ownership') }}
                  </div>
                </div>
              </div>
              <div v-else>
                <div
                  class="uk-width-1-1 uk-flex uk-flex-between"
                  tabindex="2"
                  :class="`${!isEdit ? 'uk-input-disabled' : 'uk-input'}`"
                >
                  <span class="uk-panel uk-text-truncate">
                    {{ formData.land_ownership ? formData.land_ownership.name : '-' }}
                  </span>
                  <img
                    v-if="formData.land_ownership"
                    v-lazy="`${images}/icon/eye-line.svg`"
                    alt="icon action"
                    class="icon-upload"
                    @click="handlePreviewImage('land_ownership')"
                  >
                </div>
              </div>
            </WrapperInput>
            <WrapperInput :title="'Tanda Bukti Perusahaan'">
              <div
                v-if="editMode || pathName.includes('submit-and-verification')"
              >
                <input
                  ref="company_proof"
                  v-validate="{ 'required':false }"
                  name="company_proof"
                  class="{'uk-form-danger': errors.has('company_proof')}"
                  accept="image/png,image/jpg,image/jpeg,application/pdf"
                  type="file"
                  multiple
                  :disabled="!editMode"
                  @change="(event) => handleUploadFile(event, 'company_proof')"
                >
                <div 
                  class="uk-inline"
                  tabindex="-1"
                >
                  <div
                    class="uk-input"
                  >
                    <span
                      style="max-width: 90%;"
                      class="uk-panel uk-text-truncate"
                    >
                      {{ formData.company_proof ? formData.company_proof.name : "Unggah Dokumen" }}
                    </span>
                    <img
                      v-lazy="formData.company_proof ? `${images}/icon/close-green.svg` : `${images}/icon/logout.svg`"
                      alt="icon action"
                      class="uk-form-icon uk-form-icon-flip icon-upload"
                      :class="formData.company_proof ? 'uk-form-icon-click' : ''"
                      uk-icon
                      @click="handleDelete('company_proof')"
                    >
                  </div>
                  <div
                    v-show="errors.has('company_proof')"
                    class="uk-text-small uk-text-danger"
                  >
                    {{ errors.first('company_proof') }}
                  </div>
                </div>
              </div>
              <div v-else>
                <div
                  class="uk-width-1-1 uk-flex uk-flex-between"
                  tabindex="2"
                  :class="`${!isEdit ? 'uk-input-disabled' : 'uk-input'}`"
                >
                  <span class="uk-panel uk-text-truncate">
                    {{ formData.company_proof ? formData.company_proof.name : '-' }}
                  </span>
                  <img
                    v-if="formData.company_proof"
                    v-lazy="`${images}/icon/eye-line.svg`"
                    alt="icon action"
                    class="icon-upload"
                    @click="handlePreviewImage('company_proof')"
                  >
                </div>
              </div>
            </WrapperInput>
            <WrapperInput :title="'Surat Rekomen Pihak Ke 3'">
              <div
                v-if="editMode || pathName.includes('submit-and-verification')"
              >
                <input
                  ref="third_party_recommend_letter"
                  v-validate="{ 'required':false }"
                  name="third_party_recommend_letter"
                  class="{'uk-form-danger': errors.has('third_party_recommend_letter')}"
                  accept="image/png,image/jpg,image/jpeg,application/pdf"
                  type="file"
                  multiple
                  :disabled="!editMode"
                  @change="(event) => handleUploadFile(event, 'third_party_recommend_letter')"
                >
                <div 
                  class="uk-inline"
                  tabindex="-1"
                >
                  <div
                    class="uk-input"
                  >
                    <span
                      style="max-width: 90%;"
                      class="uk-panel uk-text-truncate"
                    >
                      {{ formData.third_party_recommend_letter ? formData.third_party_recommend_letter.name : "Unggah Dokumen" }}
                    </span>
                    <img
                      v-lazy="formData.third_party_recommend_letter ? `${images}/icon/close-green.svg` : `${images}/icon/logout.svg`"
                      alt="icon action"
                      class="uk-form-icon uk-form-icon-flip icon-upload"
                      :class="formData.third_party_recommend_letter ? 'uk-form-icon-click' : ''"
                      uk-icon
                      @click="handleDelete('third_party_recommend_letter')"
                    >
                  </div>
                  <div
                    v-show="errors.has('third_party_recommend_letter')"
                    class="uk-text-small uk-text-danger"
                  >
                    {{ errors.first('third_party_recommend_letter') }}
                  </div>
                </div>
              </div>
              <div v-else>
                <div
                  class="uk-width-1-1 uk-flex uk-flex-between"
                  tabindex="2"
                  :class="`${!isEdit ? 'uk-input-disabled' : 'uk-input'}`"
                >
                  <span class="uk-panel uk-text-truncate">
                    {{ formData.third_party_recommend_letter ? formData.third_party_recommend_letter.name : '-' }}
                  </span>
                  <img
                    v-if="formData.third_party_recommend_letter "
                    v-lazy="`${images}/icon/eye-line.svg`"
                    alt="icon action"
                    class="icon-upload"
                    @click="handlePreviewImage('third_party_recommend_letter')"
                  >
                </div>
              </div>
            </WrapperInput>
            <WrapperInput :title="'Surat Rekomen Inti'">
              <div
                v-if="editMode || pathName.includes('submit-and-verification')"
              >
                <input
                  ref="core_recommend_letter"
                  v-validate="{ 'required':false }"
                  name="core_recommend_letter"
                  class="{'uk-form-danger': errors.has('core_recommend_letter')}"
                  accept="image/png,image/jpg,image/jpeg,application/pdf"
                  type="file"
                  multiple
                  :disabled="!editMode"
                  @change="(event) => handleUploadFile(event, 'core_recommend_letter')"
                >
                <div 
                  class="uk-inline"
                  tabindex="-1"
                >
                  <div
                    class="uk-input"
                  >
                    <span
                      style="max-width: 90%;"
                      class="uk-panel uk-text-truncate"
                    >
                      {{ formData.core_recommend_letter ? formData.core_recommend_letter.name : "Unggah Dokumen" }}
                    </span>
                    <img
                      v-lazy="formData.core_recommend_letter ? `${images}/icon/close-green.svg` : `${images}/icon/logout.svg`"
                      alt="icon action"
                      class="uk-form-icon uk-form-icon-flip icon-upload"
                      :class="formData.core_recommend_letter ? 'uk-form-icon-click' : ''"
                      uk-icon
                      @click="handleDelete('core_recommend_letter')"
                    >
                  </div>
                  <div
                    v-show="errors.has('core_recommend_letter')"
                    class="uk-text-small uk-text-danger"
                  >
                    {{ errors.first('core_recommend_letter') }}
                  </div>
                </div>
              </div>
              <div v-else>
                <div
                  class="uk-width-1-1 uk-flex uk-flex-between"
                  tabindex="2"
                  :class="`${!isEdit ? 'uk-input-disabled' : 'uk-input'}`"
                >
                  <span class="uk-panel uk-text-truncate">
                    {{ formData.core_recommend_letter ? formData.core_recommend_letter.name : '-' }}
                  </span>
                  <img
                    v-if="formData.core_recommend_letter"
                    v-lazy="`${images}/icon/eye-line.svg`"
                    alt="icon action"
                    class="icon-upload"
                    @click="handlePreviewImage('core_recommend_letter')"
                  >
                </div>
              </div>
            </WrapperInput>
            <div
              class="uk-child-width-expand@s uk-grid-small"
              uk-grid
            >
              <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-2-5@l uk-width-2-5@xl uk-margin-auto-vertical">
                Tanggal Administrasi
              </div>
              <div
                class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-3-5@l uk-width-3-5@xl uk-child-width-expand@s uk-grid-small"
                uk-grid
              >
                <div class="uk-width-2-5@s">
                  <template
                    v-if="editMode || pathName.includes('submit-and-verification')"
                  >
                    <datepicker
                      ref="startDate"
                      v-model="formData.submit_date_start"
                      v-validate="'required'"
                      name="submit_date_start"
                      input-class="uk-input"
                      placeholder="dd/mm/yyyy"
                      :disabled-dates="startDisabled"
                      :disabled="!editMode || isVerified"
                    />
                  </template>
                  <template
                    v-else
                  >
                    <input 
                      v-model="formData.submit_date_start"
                      input-class="uk-input"
                      class="uk-input"
                      disabled
                    >
                  </template>
                  <div
                    v-show="errors.has('submit_date_start')"
                    class="uk-text-small uk-text-danger"
                  >
                    {{ errors.first('submit_date_start') }}
                  </div>
                </div>
                <div class="uk-width-1-5@s uk-text-center filter-padding">
                  to
                </div>
                <div class="uk-width-2-5@s">
                  <template
                    v-if="editMode || pathName.includes('submit-and-verification')"
                  >
                    <datepicker
                      ref="endDate"
                      v-model="formData.submit_date_end"
                      v-validate="'required'"
                      name="submit_date_end"
                      input-class="uk-input"
                      placeholder="dd/mm/yyyy"
                      :disabled-dates="endDisabled"
                      :disabled="!formData.submit_date_start || !editMode || isVerified"
                    />
                  </template> 
                  <template
                    v-else
                  >
                    <input 
                      v-model="formData.submit_date_end"
                      input-class="uk-input"
                      class="uk-input"
                      disabled
                    >
                  </template>
                  <div
                    v-show="errors.has('submit_date_end')"
                    class="uk-text-small uk-text-danger"
                  >
                    {{ errors.first('submit_date_end') }}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          v-if="editMode || pathName.includes('submit-and-verification')"
          class="uk-child-width-expand@s uk-grid-small uk-margin-top"
          uk-grid
        >
          <div class="uk-width-1-1 uk-text-right">
            <button
              class="uk-button uk-button-danger uk-width-expand uk-width-auto@s"
              type="button"
              @click="() => handleClose(!isSav ?'close' : 'abort')"
            >
              Batal
            </button>
            <button
              v-if="isSav"
              class="uk-button uk-button-primary uk-margin-top uk-margin-remove-top-med uk-margin-remove-left@s uk-margin-left-med uk-width-expand uk-width-auto@s"
              type="button"
              @click="() => handleSubmit('save')"
            >
              <img
                v-lazy="`${images}/icon/save.svg`"
                alt="icon save"
                class="uk-margin-small-right"
              >
              Simpan
            </button>
            <button
              v-else
              class="uk-button uk-button-primary uk-margin-top uk-margin-remove-top-med uk-margin-remove-left@s uk-margin-left-med uk-width-expand uk-width-auto@s"
              type="button"
              :disabled="isLoading"
              @click="() => handleSubmit('edit')"
            >
              <span
                v-if="isLoading"
                uk-spinner
              />
              <img
                v-else
                v-lazy="`${images}/icon/save.svg`"
                alt="icon save"
                class="uk-margin-small-right"
              >
              Simpan
            </button>
          </div>
        </div>
        <div
          v-else
          class="uk-child-width-expand@s uk-grid-small uk-margin-top"
          uk-grid
        >
          <div class="uk-width-1-1 uk-text-right">
            <button
              class="uk-button uk-button-default uk-margin-top uk-margin-remove-left@s uk-margin-left-med uk-width-expand uk-width-auto@s"
              type="button"
              @click="handleDownload"
            >
              <img
                v-lazy="`${images}/icon/export.svg`"
                alt=""
                class="uk-margin-small-right img-click"
              >
              Unduh Dokumen
            </button>
            <button
              class="uk-button uk-button-primary uk-margin-top uk-margin-remove-left@s uk-margin-left-med uk-width-expand uk-width-auto@s"
              type="button" 
              :class="disabledEdit ? '-' : 'uk-button-update'"
              :disabled="disabledEdit"
              @click="handleEdit"
            >
              Ubah Data
            </button>
            <button
              v-show="dataDetail.status_acquisition == encodeStatusMitra(5)"
              class="uk-button uk-button-primary uk-margin-top uk-margin-remove-left@s uk-margin-left-med uk-width-expand uk-width-auto@s"
              type="button"
              :disabled="disabledEdit"
              @click="handleVerify"
            >
              Verifikasi Dokumen
            </button>
          </div>
        </div>
      </div>
    </div>
    <!-- Response for Pop-up Document Preview -->
    <modal-preview
      :target-preview="blobString"
      :target-field="'modal-preview-submit-verify'"
    />
    <!-- Section Response Pop-up Preview Document -->
    <modal-preview-image 
      :blob-pdf="blobPdf" 
      :target-field="'modal-preview-image'"
    />

    <!-- Response Pop-up Dokumen Pendukung -->
    <MainModal>
      <div class="wrapper-content">
        <h3 class="uk-text-center">
          Dokumen Pendukung
        </h3>
        <p class="uk-text-center">
          Apakah data ini sudah benar?
        </p>
        <div
          class="uk-child-width-expand@s uk-grid-small uk-margin-top"
          uk-grid
        >
          <div class="uk-width-1-1 uk-text-center">
            <button
              class="uk-button uk-button-default uk-width-expand uk-width-auto@s"
              type="button"
              @click="() => handleClose('close_modal')"
            >
              <img
                v-lazy="`${images}/icon/close.svg`"
                alt=""
                class="uk-margin-small-right"
              >
              Batal
            </button>
            <button
              class="uk-button uk-button-primary uk-margin-top uk-margin-remove-top-med uk-margin-remove-left@s uk-margin-left-med uk-width-expand uk-width-auto@s"
              type="button"
              :disabled="isLoading"
              @click="handleSubmitModal"
            >
              <span
                v-if="isLoading"
                uk-spinner
                class="button-spinner"
              />
              <img
                v-else
                v-lazy="`${images}/icon/save.svg`"
                alt=""
                class="uk-margin-small-right"
              >
              Simpan
            </button>
          </div>
        </div>
      </div>
    </MainModal>
    <ModalVerify />
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import { dateString, snakeToCapitalized, compressedImage, encodeStatusMitra } from '@/utils/formater'
import WrapperInput from './Component'
import BreadCrumb from '@/components/globals/breadcrumb'
import Datepicker from 'vuejs-datepicker'
import { PREFIX_IMAGE } from '@/utils/constant'
import MainModal from '@/components/globals/modal/MainModal'
import ModalPreview from '@/components/globals/modal/ModalPreview'
import ModalPreviewImage from '@/components/globals/modal/ModalPreviewPdfString'
import ModalVerify from './ModalVerify.vue'
import { stringNotifDanger, notificationSuccess } from '@/utils/notification'
import JSZip from 'jszip'
import { getUserProfile } from '@/utils/auth'

export default {
  components: {
    WrapperInput,
    Datepicker,
    BreadCrumb,
    MainModal,
    ModalPreview,
    ModalPreviewImage,
    ModalVerify
  },
  data() {
    return {
      isSav: this.$route.fullPath.includes('submit-and-verification'),
      isEdit: this.$route.fullPath.includes("edit"),
      images: PREFIX_IMAGE,
      image_url: process.env.VUE_APP_IMAGE_URL,
      pathName: this.$route.fullPath,
      isLoading: false,
      isVerified: false,
      formData: {},
      blobPdf: {},
      blobString: '',
      endDisabled: {},
      startDisabled:{},
      isNotVerified: [
        encodeStatusMitra(4),
        encodeStatusMitra(5)
      ],
      dataHeader: {
        title: 'Submit & Verifikasi',
        isBreadcrumb: true,
        breadcrumb: [
          {
            link: '/admin/akuisisi',
            title: 'Akuisisi'
          },
          {
            link: null,
            title: 'Detail Mitra'
          },
          {
            link: null,
            title: 'Submit & Verifikasi'
          }
        ]
      },
      accountOwnerSelected: {},
      landStatus: '',
      editMode: this.$route.fullPath.includes('submit-and-verification') ? true :false,
      whoVerify: '',
      accountOwnerList: [{name: 'Milik Sendiri', value: 'Milik Sendiri'}, {name: 'Orang Lain', value: 'Orang Lain'}],
      statusEdit: {},
      isTheOwner:false,
      disabledEdit: false
    }
  },
  computed : {
    ...mapGetters({
      dataSubmitVerification: 'acquisition/dataSubmitVerification',
      dataDetail: 'acquisition/dataDetail',
      getDisabledEdit: 'acquisition/disabledEdit'
    })
  },
  watch: {
    dataDetail(){
      if(this.dataDetail){
        this.landStatus = this.dataDetail.acquisition_info.land_status
        this.disabledEdit = this.getDisabledEdit
        this.startDisabled = { to: new Date(new Date(this.dataDetail.updated_at_potential).setHours(0, 0, 0, 0)) }
      }
      if(!this.isNotVerified.includes(this.dataDetail.status_acquisition)){
        this.isVerified = true
      }
    },
    'formData.submit_date_start'() {
      if(new Date(new Date(this.formData.submit_date_start).getTime()) > new Date(new Date(this.formData.submit_date_end).getTime())){
        this.formData.submit_date_end = ''
      }
      this.endDisabled = { to: new Date(this.formData.submit_date_start) }
    },
    'formData.submit_date_end'() {
      if(new Date(new Date(this.formData.submit_date_start).getTime()) > new Date(new Date(this.formData.submit_date_end).getTime())){
        this.formData.submit_date_start = ''
      }
    },
    'formData.account_bank_owner'() {
      if(this.formData.account_bank_owner){
        this.accountOwnerSelected = true
        if(this.formData.account_bank_owner.value != 'Milik Sendiri'){
          this.isTheOwner = false
        } else {
          this.isTheOwner = true
        }
      } else {
        this.accountOwnerSelected = false
      }
    }
  },
  async beforeMount() {
    if(this.isSav){
      await this.getDataDetail(`${this.$route.params.id}`)
      if (this.dataDetail.status_acquisition != encodeStatusMitra(4)) {
        this.$router.push(`/admin/akuisisi/detail/${this.$route.params.id}`)
        setTimeout(() => {
          stringNotifDanger(
            this.dataDetail.status_acquisition == encodeStatusMitra(5) ? 
              'Status Dokumen Menunggu Verifikasi!' : `Status Akuisisi Di Tahap ${this.dataDetail.status_acquisition}`)
        }, 1000)
      }
    }
  },
  async mounted() {
    if(this.dataDetail){
      this.handleDataDetail()
    }
    this.whoVerify = getUserProfile()
  },
  methods: {
    encodeStatusMitra(n){
      return encodeStatusMitra(n)
    },
    ...mapActions({
      getPartnerSubmitVerification: 'acquisition/getPartnerSubmitVerification',
      editPartnerSubmitVerification: 'acquisition/editPartnerSubmitVerification',
      getDataDetail: 'acquisition/getDataDetail'
    }),
    handleDataDetail() {
      if (!this.pathName.includes('submit-and-verification')) 
      {
        if (this.dataDetail) {
          const detailVerify = this.dataDetail.acquisition_verification
          for (var key in detailVerify) {
            if (typeof detailVerify[key] == 'string') {
              if(key == 'submit_date_start' || key == 'submit_date_end'){
                this.formData = {
                  ...this.formData,
                  [key]: this.dateFormatFilter(detailVerify[key]).replace(/-/g, "/")
                }
              } else if( key == 'account_bank_owner'){
                this.formData = {
                  ...this.formData,
                  [key]: this.accountOwnerList.filter(item => item.name == detailVerify.account_bank_owner)
                }
              } else {this.formData = {
                ...this.formData,
                [key]: !detailVerify[key].includes('acquisition/verification') ? detailVerify[key] : {
                  name: detailVerify[key] ? detailVerify[key].split('/')[3] : '',
                  data: detailVerify[key]
                }
              }
              }
            }
          }
        }
      }
    },
    dateFormatFilter(date) {
      return dateString(date)
    },
    async handleUploadFile(data, target) {
      const file = data.target.files[0]
      const fileType = file.type
      const fileSize = file.size
      const maxFileSize = 1048576
      const allowedTypes = ["application/pdf", "image/jpeg", "image/png", "image/gif"]
      if (file) {
        if (allowedTypes.includes(fileType)) {
          if(file.type != allowedTypes[0]){
            const compressedFile = await compressedImage(file, 1)
            this.formData = {
              ...this.formData,
              [target]: {
                data: compressedFile,
                name: compressedFile.name
              }
            }
          } else {
            if(fileSize > maxFileSize){
              stringNotifDanger("Maximum PDF File: 1MB")
            } else {
              this.formData = {
                ...this.formData,
                [target]: {
                  data: file,
                  name: file.name
                }
              }
            }
          }
        } else {
          stringNotifDanger("Please select a PDF or an image file.")
        }
      }
    },
    handlePreviewImage(target) {
      const dataPreview = this.formData[target].data
      if(!this.formData[target].data.includes('pdf')){
        this.blobString = dataPreview.includes('base64') ? dataPreview : this.image_url + dataPreview
        window.UIkit.modal('#modal-preview-submit-verify').show()
      } else {
        this.blobPdf = {
          name: snakeToCapitalized(target), 
          file: dataPreview ? this.image_url + dataPreview : target
        }
        return window.UIkit.modal('#modal-preview-image').show()
      }
    },
    handleDelete(target) {
      if (this.formData[target] !== undefined) {
        const inputElement = this.$refs[target]
        inputElement.value = '' // Clear the file input value
        this.$delete(this.formData, target)
        this.formData[target] = null // Reset the field value to null or empty
        this.$nextTick(() => {
          this.$validator.errors.removeById(target) // Remove specific error for the deleted field
          this.$validator.validate(`formData.${target}`).catch(() => {}) // Validate only the deleted field
        })
      }
    },
    handleClose(field) {
      if (field == 'close') {
        window.location.reload()
      }else if(field == 'abort'){
        this.$router.push(`/admin/akuisisi/detail/${this.$route.params.id}`)
      }else {
        this.isLoading = false
        window.UIkit.modal('#modal-standart').hide()
      }

    },
    handleSubmitModal(){
      this.handleSubmit('save_modal')
    },
    async handleSubmit(field) {
      if (field == 'save') {
        this.$validator.validateAll().then((success) => {
          if (success) {
            if (this.$validator.errors.any()) return
            window.UIkit.modal('#modal-standart').show()
          }
        })
      } else if (this.pathName.includes('submit-and-verification')) {
        this.$validator.validateAll().then(async (success) => {
          if (success) {
            if (this.$validator.errors.any()) return
            let formData = new FormData()
            this.isLoading = true
            formData.append('account_bank_owner',this.formData.account_bank_owner.value)
            formData.append('acquisition_id', this.dataDetail.id)
            for (var keys in this.formData) {
              if(!this.formData[keys].value){
                if(this.formData[keys].data ){
                  formData.append(keys, this.formData[keys].data)
                } else if(this.formData[keys].name){
                  formData.append(keys, this.formData[keys].name)
                }else if(keys.includes('submit_date')){
                  formData.append(keys, this.dateFormatFilter(this.formData[keys]))
                }else {
                  formData.append(keys, this.formData[keys])
                }
              }
            }
            await this.getPartnerSubmitVerification(formData)
            const res  = await this.$store.getters['acquisition/dataSubmitVerification']
            if(await res.toLowerCase() == 'ok'){
              this.isLoading = false
              setTimeout(()=> {
                window.location.href = `/admin/akuisisi/detail/${this.$route.params.id}`
              }, 1000)
            } else {
              this.isLoading = false
            }
          }
        })
      } else {
        this.$validator.validateAll().then(async (success) => {
          if (success) {
            if (this.$validator.errors.any()) return
            if(this.editMode){
              this.isLoading = true
              let formDataEdit = new FormData()
              formDataEdit.append('acquisition_id', this.dataDetail.id)
              for (var key in this.formData) {
                if(typeof this.formData[key].data != 'string'  && !this.formData[key] == ''){
                  if(key.includes('submit_date')){
                    formDataEdit.append(key, this.dateFormatFilter(this.formData[key]))
                  } else if(key.includes('account_bank_owner') && !key.includes('family')){
                    formDataEdit.append(key, this.formData[key].name ? this.formData[key].name : this.formData[key][0].name )
                  } else {
                    this.formData[key].data ?
                      formDataEdit.append(key, this.formData[key].data) :
                      formDataEdit.append(key, this.formData[key])
                  }
                }
              } 
              await this.editPartnerSubmitVerification(formDataEdit)
              const res  = await this.$store.getters['acquisition/dataSubmitVerificationEdit']
              if(await res.toLowerCase() == 'ok'){
                notificationSuccess('Berhasil Ubah Dokumen Pendukung')
                setTimeout(()=> {
                  window.location.reload()
                }, 1000)
              } else {
                this.isLoading = false
              }
            }
          }
        })
      }
    },
    handleEdit(){
      this.editMode = true
    },
    async handleDownload() {
      let urls = []
      let acquisitionVerification = this.dataDetail.acquisition_verification
      let mitra = this.dataDetail
      for (let value of Object.values(acquisitionVerification)) {
        if (typeof value === 'string' && value.includes('/')) {
          urls.push(this.image_url+value)
        }
      }

      // create a new instance of JSZip
      const zip = new JSZip()

      // iterate over the URLs and fetch the data for each URL
      for (let url of urls) {
        const response = await fetch(url)
        const data = await response.arrayBuffer()

        // add the fetched data to the JSZip instance
        zip.file(url.split('/').pop(), data)
      }
      // generate the ZIP file and download it
      const content = await zip.generateAsync({ type: "blob" })
      const filename = `Dokumen Pendukung - Mitra ${mitra.name}.zip`
      const link = document.createElement("a")
      link.href = URL.createObjectURL(content)
      link.download = filename
      link.click()
    },
    async handleVerify(){
      window.UIkit.modal('#modal-verify').show()
    }
  }
}
</script>

<style scoped>
  .uk-form-icon-click{
    pointer-events: auto;
    z-index: 1;
  }
  .icon-upload {
    padding: 8px;
    cursor: pointer;
  }

  .icon-eye {
    padding: 12px;
  }

  .wrapper-content h3 {
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 150%;
    color: #025231;
  }

  .wrapper-content p {
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 150%;
    color: #1F2E28;
  }

 .uk-button-update{
  background-color: #1EC887 !important;
 }
</style>
